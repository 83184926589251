var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "van-loading",
            { staticClass: "information-van-loading", attrs: { size: "24px" } },
            [_c("span", [_vm._v(_vm._s("加载中···"))])]
          )
        ],
        1
      ),
      _c("information-page", {
        attrs: {
          "button-title": _vm.buttonTitle,
          "page-title": _vm.pageTitle,
          "van-icon": _vm.vanIcon,
          "path-name": _vm.pathName,
          "icon-class": _vm.iconClass
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }