<template>
  <div>
    <van-overlay :show="loading">
      <van-loading size="24px" class="information-van-loading">
        <span>{{ '加载中···' }}</span>
      </van-loading>
    </van-overlay>
    <information-page :button-title="buttonTitle" :page-title="pageTitle" :van-icon="vanIcon" :path-name="pathName" :icon-class="iconClass" />
  </div>
</template>
<script>
import InformationPage from '@/components/information-page'
import { Loading, Overlay } from 'vant'
export default {
  components: {
    InformationPage,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay
  },
  data () {
    return {
      BizToken: this.$route.query.BizToken,
      loading: false,
      pageTitle: '',
      lessTitle: '',
      vanIcon: '',
      pathName: '',
      iconClass: '',
      buttonTitle: ''
    }
  },
  created () {
    this.loading = true
    this.utils.faceDetectAuth(this.BizToken).then(result => {
      this.loading = false
      this.pageTitle = '恭喜您，身份认证通过！'
      this.lessTitle = ''
      this.vanIcon = 'checked'
      this.pathName = 'signatureEmpowerSuccess'
      this.iconClass = 'icon-class'
      this.buttonTitle = '下一步'
    }, () => {
      this.loading = false
      this.pageTitle = '身份认证失败，请重新认证'
      this.lessTitle = ''
      this.vanIcon = 'clear'
      this.pathName = 'signatureGrant'
      this.iconClass = ''
      this.buttonTitle = '返回'
    })
  },
  methods: {
  }
}
</script>

<style>

</style>
